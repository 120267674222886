import { List, ListItem, ListItemButton } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

import { Text } from "../../shared/uiComponents";
import { black900, white100 } from "../../shared/Helpers/colors";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import { MenuProperties } from "../../App/constants";
import { useState } from "react";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@mui/icons-material";

const MenuElements = ({ menu }: { menu: Array<MenuProperties> }) => {
  return (
    <List>
      <Text title={"Menu"} size="mediumBold" className="paddingLeft8 title" />
      {menu.map((item, index) => (
        <LinkItem key={index} item={item} />
      ))}
    </List>
  );
};

const LinkItem = ({ item }: { item: MenuProperties }) => {
  const { pathname } = useLocation();
  const { permissionGranted } = useCheckPermission(item.permission);
  const [openSubMenu, setOpenSubMenu] = useState<boolean>(false);

  const toggleSubMenuHandler = () => setOpenSubMenu(!openSubMenu);

  if (!permissionGranted) return null;
  return !item.subMenu ? (
    <Link className="menuLink" to={item.url}>
      <ListItem disablePadding>
        <ListItemButton selected={pathname.includes(item.url)}>
          <Text
            title={item.title}
            size="smallBold"
            textColor={pathname.includes(item.url) ? white100 : black900}
            onClick={() => {}}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  ) : (
    <>
      <div
        style={{
          padding: "8px 16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        onClick={toggleSubMenuHandler}
      >
        <Text title={item.title} size="smallBold" />
        {!openSubMenu ? <ExpandMoreOutlined /> : <ExpandLessOutlined />}
      </div>
      <div
        style={{
          transition: "max-height 306ms cubic-bezier(0.4, 0, 0.2, 1)",
          maxHeight: openSubMenu ? `${item.subMenu.length * 40}px` : "0px",
          cursor: "pointer",
          overflow: "hidden",
          marginLeft: "16px",
        }}
      >
        {item.subMenu.map((x, index) => <SubMenuItem item={x} key={index} />)}
      </div>
    </>
  );
};

const SubMenuItem = ({ item }: { item: MenuProperties }) => {
  const { pathname } = useLocation();
  const { permissionGranted } = useCheckPermission(item.permission);
  if (!permissionGranted) return null;
  return (
    <Link className="menuLink" to={item.url}>
      <ListItem disablePadding>
        <ListItemButton selected={pathname.includes(item.url)}>
          <Text
            title={item.title}
            size="smallBold"
            textColor={pathname.includes(item.url) ? white100 : black900}
            onClick={() => {}}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};

export default MenuElements;
