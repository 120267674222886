import { FC } from "react";
import { styled } from "@mui/material";

import { black500, blue100, green200, white100 } from "../../Helpers/colors";

enum StatusColor {
  "#68B0BC", // blue400 (Pending Insurance Authorization)
  "#90BA3C", // green200 (Active)
  "#FE6F5E", // red300 (Paused)
  "#F1F1F1", // gray100 (Discharged)
}

enum Status {
  "Pending Insurance Authorization",
  "Active",
  "Paused",
  "Discharged",
}

interface ClientStatusBadgeProperties {
  statusCode: number;
}

const StatusWrapper = styled("div")<{ statusCode: number }>(
  ({ statusCode }) => ({
    backgroundColor: StatusColor[statusCode],
    color: statusCode === 3 ? black500 : white100,
    width: "max-content",
    borderRadius: "20px",
    padding: "6px 18px",
    marginLeft: "-16px",
  })
);

export const ClientStatusBadge: FC<ClientStatusBadgeProperties> = ({
  statusCode,
}) => {
  return (
    <StatusWrapper statusCode={statusCode}>{Status[statusCode]}</StatusWrapper>
  );
};

export const UserStatusBadge: FC<{ active: boolean }> = ({ active }) => (
  <div
    style={{
      backgroundColor: !active ? blue100 : green200,
      color: white100,
      width: "max-content",
      borderRadius: "20px",
      padding: "6px 18px",
      marginLeft: "-16px",
    }}
  >
    {active ? "Active" : "Deactive"}
  </div>
);
