import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { InfoOutlined as Info } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { CustomAlert, CustomButton, Text } from "../../shared/uiComponents";
import { blue100, green200, white100 } from "../../shared/Helpers/colors";
import { errorNormalizer } from "../../shared/Helpers/functions";
import { Loader, InfoRow as Row } from "../StyledComponents";
import UserInfo from "./userInfo";
import InsuranceList from "./insuranceList";
import ClientsList from "./clientList";

import { DispatchProperties, useSelector } from "../../redux/store";
import {
  getUserAccountDetails,
  resendVerificationEmail,
} from "../../redux/State/identitySlice/userSlice";
import { UserProperties } from "../../redux/API/userAPIProperties";

const AccountDetails = ({ item }: { item: UserProperties }) => {
  const [infoOpen, setInfoOpen] = useState<boolean>(false);

  const showUserInfo = () => {
    setInfoOpen(true);
  };

  return (
    <>
      <div
        onClick={showUserInfo}
        className={"marginRight16"}
        style={{ cursor: "pointer" }}
      >
        <Tooltip title="More Information">
          <Info />
        </Tooltip>
      </div>
      <CustomAlert
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        title={"Account Details"}
        Content={({ onClose }) => <Content item={item} onClose={onClose} />}
      />
    </>
  );
};

const Content = ({
  onClose,
  item,
}: {
  onClose: () => void;
  item: UserProperties;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const accountInfo = useSelector((state) => state.identityUser.accountInfo);

  const loading = useSelector((state) => state.identityUser.loadingAccountInfo);

  const resendVerificationHandler = () => {
    if (!item.id) return;
    dispatch(resendVerificationEmail(item.id))
      .then(unwrapResult)
      .then(() => toast("Success"))
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  useEffect(() => {
    if (!item.id) return;
    dispatch(getUserAccountDetails(item.id));
  }, [item.id, dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Row>
        <Text
          title={"Email confirmed:"}
          size={"smallBold"}
          className="marginLeft8"
        />
        <div>
          <Text
            title={accountInfo.emailConfirmed ? "Confirmed" : "Pending"}
            textColor={white100}
            style={{
              backgroundColor: !accountInfo.emailConfirmed ? blue100 : green200,
              width: "max-content",
              borderRadius: "20px",
              padding: "6px 18px",
              marginLeft: "-16px",
            }}
          />
        </div>
      </Row>
      <UserInfo accountInfo={accountInfo} userInfo={item} />
      <InsuranceList id={item.id} />
      <ClientsList item={item} />
      <CustomButton
        title="Resend Verification"
        onClick={resendVerificationHandler}
        disabled={accountInfo.emailConfirmed}
      />
    </>
  );
};

export default AccountDetails;
