import { FetchAPI } from "../Main/fetchAPI";
import { CLIENT_INSURANCE_AUTHORIZATION, INSURANCE_AUTHORIZATION } from "../constants";
import {
    AddAuthorizationProperties,
    AuthorizationProperties,
    GetAllocatedHoursDetailAuthorizationProperties,
    GetAvailableHoursDetailProperties,
    GetCalculatedHoursRangeProperties,
    GetInsuranceAuthorizationsProperties,
    GetUtilizationReportProperties,
    UpdateAllocatedHoursProperties,
    UpdateInsuranceAuthorization,
    UserAllocatedHoursDetailProperties,
} from "./insuranceAuthorizationProperties";

export const InsuranceAuthorization = {
    getUtilizationReport: (data: GetUtilizationReportProperties) => {
        const { endDate, startDate, clientIds } = data;
        const query = new URLSearchParams();
        let ids = ''
        if (!!clientIds.length) {
            ids = clientIds.join('&clientIds=');
        };
        if (!!startDate) query.set('startDate', startDate);
        if (!!endDate) query.set('endDate', endDate);
        return FetchAPI.getJson({
            urlStr: `${INSURANCE_AUTHORIZATION}/utilization-report?${query.toString()}${!!clientIds.length ? `&clientIds=${ids}` : ''}`,
        })
    },
    getInsuranceAuthorizationSessionTypes: (type: string) => FetchAPI.getJson({
        urlStr: `${INSURANCE_AUTHORIZATION}/session-types?authorizationType=${type}`,
    }),
    getInsuranceAuthorizationPerWeekData: (data: GetCalculatedHoursRangeProperties) => {
        const { endDate, startDate, totalHours } = data;
        const query = new URLSearchParams();

        if (!!startDate) query.set('startDate', startDate);
        if (!!endDate) query.set('endDate', endDate);
        if (!!totalHours) query.set('totalHours', `${totalHours}`);

        return FetchAPI.getJson({ urlStr: `${INSURANCE_AUTHORIZATION}/hours-per-week?${query.toString()}` })
    },
    getActiveInsuranceAuthorizations: ({ clientId, userId }: { clientId: string, userId?: string }) => FetchAPI.getJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/active${!!userId ? `?userId=${userId}` : ''}`
    }),
    getInsuranceAuthorizations: (data: GetInsuranceAuthorizationsProperties) => {
        const { clientId, page, pageSize } = data;
        const query = new URLSearchParams();
        if (!!page) query.set('page', page);
        if (!!pageSize) query.set('pageSize', pageSize);
        return FetchAPI.getJson({
            urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}?${query.toString()}`,
        })
    },
    saveInsuranceAuthorizations: (clientId: string, data: AddAuthorizationProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}`,
        data
    }),
    saveAssessmentInsuranceAuthorizations: (clientId: string, data: AuthorizationProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/assessment`,
        data
    }),
    saveTreatmentAuthorizations: (clientId: string, data: AuthorizationProperties) => FetchAPI.postJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/treatment`,
        data
    }),
    getAvailableHoursDetails: ({ clientId, authorizationId }: GetAvailableHoursDetailProperties) => FetchAPI.getJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/${authorizationId}/available-hours`,
    }),
    getAllocatedHoursDetailsUser: ({ clientId, authorizationId, userId }: UserAllocatedHoursDetailProperties) => FetchAPI.getJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/${authorizationId}/allocated-hours/${userId}`,
    }),
    updateAllocatedHours: (data: UpdateAllocatedHoursProperties) => {
        const { clientId, authorizationId } = data;
        return FetchAPI.postJson({
            urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/${authorizationId}/allocate`,
            data
        })
    },
    getAllocatedHoursAuthorizationDetails: ({ clientId, authorizationId, authorizationDetailId }: GetAllocatedHoursDetailAuthorizationProperties) => FetchAPI.getJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(clientId)}/${authorizationId}/details/${authorizationDetailId}/allocated-hours`,
    }),
    editAuthorization: (data: UpdateInsuranceAuthorization) => FetchAPI.putJson({
        urlStr: `${CLIENT_INSURANCE_AUTHORIZATION(data.clientId)}/${data.authorizationId}`,
        data
    }),
}