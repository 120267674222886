import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { resetState } from "../../resetState";
import { ClientsDataProperties } from "../../../pages/Clients/tableInfo";
import API from "../../API/Main/API";
import { AssignIdsProperties, } from "../../API/ClientAPIHelpers/userClientProperties";
import {
    AddClientProperties,
    ClientPaginationProperties,
    DiagnosisCodeProperties,
    InfoClientProperties,
    UpdateClientInfoProperties,
} from "../../API/ClientAPIHelpers/clientProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface ClientStateProperties {
    loading: boolean;
    clients: ClientsDataProperties;
    diagnosisCodes: Array<DiagnosisCodeProperties> | null;
    therapistsListForAssign: Array<AssignIdsProperties>;
    clientsListForAssign: Array<AssignIdsProperties>;
    client: InfoClientProperties;
    insurances: Array<{
        id: string;
        name: string
    }>;
    statuses: Array<{
        id: string;
        name: string
    }>;
    error: ErrorProperties;
}

const initialClientState: ClientStateProperties = {
    loading: false,
    error: {
        status: 0,
        title: ''
    },
    diagnosisCodes: null,
    clients: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },

    therapistsListForAssign: [],
    clientsListForAssign: [],
    client: {
        id: "",
        firstName: "",
        lastName: "",
        nickName: "",
        dateOfBirthday: "",
        fullName: "",
        numberOfTherapist: 0,
        gender: "",
        email: "",
        secondaryEmail: "",
        secondaryNumber: "",
        parentPhoneNumber: "",
        address: "",
        insuranceNumber: "",
        city: "",
        zip: "",
        defaultBcba: {
            id: "",
            fullName: "",
            firstName: "",
            lastName: "",
            email: "",
            role: {
                id: '',
                name: "",
                section: {
                    id: 0,
                    name: ""
                }
            },
            authorizationType: {
                id: 0,
                name: ""
            },
        },
        status: {
            id: 0,
            name: ""
        },
        insurance: {
            id: "",
            name: "",
        },
        diagnosisCode: {
            id: "",
            name: ""
        }
    },
    insurances: [],
    statuses: [],
}

export const getDiagnosisCodes = createAsyncThunk(
    '/client/clients/diagnosis-code',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.DiagnosisCode.getDiagnosisCode();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<DiagnosisCodeProperties>;
    }
)

export const getClients = createAsyncThunk(
    '/client/clients',
    async (data: ClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.Client.getClients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as ClientsDataProperties;
    }
)

export const addClient = createAsyncThunk(
    '/client/add',
    async (data: AddClientProperties, thunkAPI) => {
        const response = await API.ClientAPI.Client.addClient(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getClient = createAsyncThunk(
    '/client/client',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.Client.getClient(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as InfoClientProperties;
    }
)

export const updateClient = createAsyncThunk(
    '/client/update',
    async (data: UpdateClientInfoProperties, thunkAPI) => {
        const response = await API.ClientAPI.Client.updateClient(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteClient = createAsyncThunk(
    '/client/delete',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.Client.deleteClient(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getInsurances = createAsyncThunk(
    '/client/insurances',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.Insurances.getInsurances();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<{
            id: string;
            name: string
        }>;
    }
)

export const getStatuses = createAsyncThunk(
    '/client/statuses',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.Client.getClientStatuses();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const clientSlice = createSlice({
    name: 'client',
    initialState: initialClientState,
    reducers: {
        addTherapist(state, action) {
            state.therapistsListForAssign = [...state.therapistsListForAssign, action.payload];
        },
        updateTherapist(state, action) {
            const newList = state.therapistsListForAssign.map(x => {
                if (x.id === action.payload.id) return action.payload;
                return x;
            })
            state.therapistsListForAssign = newList;
        },
        clearTherapists(state) {
            state.therapistsListForAssign = initialClientState.therapistsListForAssign;
        },
        addClientAssignList(state, action) {
            const newList = [...state.clientsListForAssign];
            newList.push(action.payload);
            state.clientsListForAssign = newList;
        },
        updateClientAssignList(state, action) {
            const newList = state.clientsListForAssign.map(x => {
                if (x.id === action.payload.id) return action.payload;
                return x;
            })
            state.clientsListForAssign = newList;
        },
        clearClients(state) {
            state.clientsListForAssign = initialClientState.clientsListForAssign;
        },
    },
    extraReducers: (builder) => {
        //getDiagnosisCodes
        builder.addCase(getDiagnosisCodes.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(getDiagnosisCodes.fulfilled, (state, action) => {
            state.loading = false;
            state.diagnosisCodes = action.payload;
        })
        builder.addCase(getDiagnosisCodes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getStatuses
        builder.addCase(getStatuses.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(getStatuses.fulfilled, (state, action) => {
            state.loading = false;
            state.statuses = action.payload;
        })
        builder.addCase(getStatuses.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get insurances
        builder.addCase(getInsurances.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(getInsurances.fulfilled, (state, action) => {
            state.loading = false;
            // const insurances = [{ id: '', name: 'N/A' }, ...action.payload]
            state.insurances = action.payload;
        })
        builder.addCase(getInsurances.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get Clients
        builder.addCase(getClients.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(getClients.fulfilled, (state, action) => {
            state.loading = false;
            state.clients = action.payload;
        })
        builder.addCase(getClients.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get Client
        builder.addCase(getClient.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(getClient.fulfilled, (state, action) => {
            state.loading = false;
            state.client = action.payload;
        })
        builder.addCase(getClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //add Client
        builder.addCase(addClient.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(addClient.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(addClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //update Client
        builder.addCase(updateClient.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(updateClient.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //delete Client
        builder.addCase(deleteClient.pending, (state) => {
            state.loading = true;
            state.error = initialClientState.error;
        })
        builder.addCase(deleteClient.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteClient.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //reset state
        builder.addCase(resetState, () => initialClientState);
    }
})

export const {
    addTherapist,
    updateTherapist,
    clearTherapists,
    addClientAssignList,
    clearClients,
    updateClientAssignList,
} = clientSlice.actions;
export default clientSlice.reducer;