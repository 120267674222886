import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  AddOutlined as Add,
  RemoveOutlined as Remove,
} from "@mui/icons-material";

import { white200 } from "../../../../shared/Helpers/colors";
import { CustomAlert, Text } from "../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../redux/store";
import { BehaviorReductionProperties } from "../../../../redux/API/ClientAPIHelpers/behaviorReductionProperties";
import {
  getFrequency,
  updateFrequency,
} from "../../../../redux/State/clientSlice/dataCollectionSlice";

const FrequencyTypeBehavior = ({
  behavior,
}: {
  behavior: BehaviorReductionProperties;
}) => {
  const { sessionId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const [count, setCount] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    if (!behavior.id || !sessionId || flag) return;
    dispatch(getFrequency({ behaviorId: behavior.id, sessionId }))
      .then(unwrapResult)
      .then((response) => {
        setCount(response.count);
        setFlag(false);
      })
      .catch(errorNormalizer);
  }, [behavior, sessionId, flag, dispatch]);

  const addBehaviourFrequency = () => {
    setCount((prev) => prev + 1);
    setFlag(true);
  };

  const removeBehaviourFrequency = () => {
    setCount((prev) => prev - 1);
    setFlag(true);
  };

  useEffect(() => {
    if (!behavior.id || !sessionId || !flag) return;
    const timeout = setTimeout(() => {
      dispatch(
        updateFrequency({
          behaviorId: behavior.id,
          sessionId,
          count,
        })
      );
    }, 1000);
    return () => clearTimeout(timeout);
  }, [behavior, sessionId, count, flag, dispatch]);

  return (
    <>
      <div
        className="marginRight8 "
        style={{
          width: "32px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          backgroundColor: white200,
          borderRadius: "8px",
        }}
      >
        <Text title={count} onClick={() => setOpen(true)} size="mediumBold" />
      </div>
      <div
        className="marginRight8"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          cursor: "pointer",
        }}
      >
        <Text
          title={behavior.name}
          onClick={addBehaviourFrequency}
          className="marginRight8"
        />
      </div>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={() => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
              className="padding16"
            >
              <Remove fontSize="large" onClick={removeBehaviourFrequency} />
              <Text title={count} size="largeBold" />
              <Add fontSize="large" onClick={addBehaviourFrequency} />
            </div>
          );
        }}
      />
    </>
  );
};

export default FrequencyTypeBehavior;
