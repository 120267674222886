import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";

import {
  TableNoData,
  Text,
  ClientStatusBadge,
  CustomAccordion,
} from "../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../shared/uiComponents/Table/tableProperties";
import { blue400 } from "../../shared/Helpers/colors";
import { InfoRow as Row } from "../../components/StyledComponents";
import Action from "./actions";

import { DispatchProperties, useSelector } from "../../redux/store";
import { InfoClientProperties } from "../../redux/API/ClientAPIHelpers/clientProperties";
import {
  getDefaultBCBA,
  getTherapists,
} from "../../redux/State/clientSlice/userClientSlice";
import { clearSkillAreas } from "../../redux/State/clientSlice/skillAreaSlice";

export interface RowRendererProperties {
  data: Array<InfoClientProperties>;
}

export interface ClientsDataProperties extends TableDataProperties {
  query: Array<InfoClientProperties> | null;
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  { id: "1", name: "Email", orderBy: "email" },
  { id: "2", name: "Provider", orderBy: "numberOfTherapist" },
  { id: "3", name: "Parent Phone", orderBy: "parentPhoneNumber" },
  {
    id: "4",
    name: "Status",
    orderBy: "status",
    titles: ["Pending", "Active", "Paused", "Discharged", "All"], //all keyword is for reseting column
    titlesHeader: "Select Status:",
  },
  { id: "5", name: "Actions", width: "120px" },
];

export const ClientDetailedInfo: FC<InfoClientProperties> = (info) => {
  const dispatch = useDispatch<DispatchProperties>();

  const therapists = useSelector((state) => state.userClient.therapists);
  const defaultBCBA = useSelector((state) => state.userClient.defaultBCBA);

  useEffect(() => {
    if (!info.id) return;
    dispatch(getTherapists(info.id));
    dispatch(getDefaultBCBA(info.id));
  }, [info, dispatch]);

  return (
    <>
      <Row>
        <Text title={"First Name: "} size={"smallBold"} />
        <Text title={info.firstName} />
      </Row>
      <Row>
        <Text title={"Last Name: "} size={"smallBold"} />
        <Text title={info.lastName} />
      </Row>
      <Row>
        <Text title={"Nickame: "} size={"smallBold"} />
        <Text title={!!info.nickName ? info.nickName : "-"} />
      </Row>
      <Row>
        <Text title={"Email: "} size={"smallBold"} />
        <Text title={info.email} />
      </Row>
      <Row>
        <Text title={"Secondary Email: "} size={"smallBold"} />
        <Text title={!!info.secondaryEmail ? info.secondaryEmail : "-"} />
      </Row>
      <Row>
        <Text title={"Parent Phone Number: "} size={"smallBold"} />
        <Text title={info.parentPhoneNumber} />
      </Row>
      <Row>
        <Text title={"Secondary Phone Number: "} size={"smallBold"} />
        <Text title={!!info.secondaryNumber ? info.secondaryNumber : "-"} />
      </Row>
      <Row>
        <Text title={"DOB: "} size={"smallBold"} />
        <Text title={dayjs(info.dateOfBirthday).format("MM-DD-YYYY")} />
      </Row>
      <Row>
        <Text title={"Gender: "} size={"smallBold"} />
        <Text title={info.gender} />
      </Row>
      <Row>
        <Text title={"Address: "} size={"smallBold"} />
        <Text title={info.address} />
      </Row>
      <Row>
        <Text title={"City: "} size={"smallBold"} />
        <Text title={info.city} />
      </Row>
      <Row>
        <Text title={"Zip: "} size={"smallBold"} />
        <Text title={info.zip} />
      </Row>
      <Row>
        <Text title={"Insurance: "} size={"smallBold"} />
        <Text title={info.insurance?.name} />
      </Row>
      <Row>
        <Text title={"Insurance ID: "} size={"smallBold"} />
        <Text title={info.insuranceNumber} />
      </Row>
      <CustomAccordion
        headerStyle={{ padding: 0 }}
        HeaderContent={() => (
          <Row>
            <Text title={"Number of Providers: "} size={"smallBold"} />
            <Text title={`${info.numberOfTherapist}`} />
          </Row>
        )}
        Content={() =>
          !!therapists?.length ? (
            <>
              {therapists?.map((therapist, index) => (
                <Text
                  key={index}
                  title={`${therapist.fullName} ${
                    defaultBCBA?.id === therapist.id ? "(Supervisor)" : ""
                  }`}
                  className={"marginRight8"}
                />
              ))}
            </>
          ) : (
            <Text title={"None"} />
          )
        }
      />
    </>
  );
};

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const navigateClientHandler = () => {
    dispatch(clearSkillAreas());
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell padding="none">
            <Link to={`/clients/client/${row.id}`}>
              <Text
                title={row.fullName}
                textColor={blue400}
                size="tiny"
                className="paddingLeft16"
                onClick={navigateClientHandler}
              />
            </Link>
          </TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>{row.numberOfTherapist}</TableCell>
          <TableCell>
            {!!row.parentPhoneNumber ? row.parentPhoneNumber : "-"}
          </TableCell>
          <TableCell>
            <ClientStatusBadge statusCode={row.status.id} />
          </TableCell>
          <TableCell>
            <Action item={row} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
