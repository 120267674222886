import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  PersonOutlined as Person,
  EmailOutlined as Email,
  AdminPanelSettingsOutlined as Admin,
  PermContactCalendarOutlined as Auth,
  CheckOutlined,
} from "@mui/icons-material";

import {
  Text,
  MobilePagination,
  // UserStatusBadge,
} from "../../shared/uiComponents";
import { SelectDataProperties } from "../../shared/uiComponents/Dropdown";
import { Loader, Row, Wrapper } from "../../components/StyledComponents";
import MobileSort from "../../components/MobileSort";
import UserAction from "../../components/UserAction";
import { AdminDataProperties } from "./tableInfo";

import { getUsers } from "../../redux/State/userSlice";
import { DispatchProperties, useSelector } from "../../redux/store";

export const sortData: Array<SelectDataProperties> = [
  { id: "fullName", name: "Full Name" },
  { id: "email", name: "Email" },
  { id: "phoneNumber", name: "Phone Number" },
];

export const statusData: Array<SelectDataProperties> = [
  { id: "all", name: "All" },
  { id: "0", name: "Admin" },
  { id: "1", name: "BCBA" },
  { id: "2", name: "BT" },
];

const MobileView: FC<AdminDataProperties> = (data) => {
  const { query, totalNumberOfItems } = data;
  const [params] = useSearchParams();
  const loading = useSelector((state) => state.user.loading);

  const dispatch = useDispatch<DispatchProperties>();

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const section = params.get("status");

    dispatch(
      getUsers({ page, pageSize, orderBy, direction, searchString, section })
    );
  };

  return (
    <div className={"mobileView"}>
      <MobileSort data={sortData} hasStatus={true} statusData={statusData} />
      {loading && <Loader />}
      {!loading && !totalNumberOfItems && (
        <div className={"mobileView"}>
          <Text
            title={"No results"}
            size={"smallBold"}
            className={"marginAuto"}
          />
        </div>
      )}
      {!loading &&
        !!query &&
        query.map((item, index) => (
          <Wrapper key={index}>
            <Row style={{ justifyContent: "flex-end" }}>
              <UserAction item={item} fetchUsers={fetchUsers} />
            </Row>
            <Row>
              <Person />
              <Text title={item.fullName} className={"marginLeft8"} />
            </Row>
            <Row style={{ justifyContent: "space-between" }}>
              <div
                style={{ display: "flex", gap: "8px", alignItems: "center" }}
              >
                <Email /> <Text title={item.email} />
              </div>
              {item.emailConfirmed && (
                <CheckOutlined style={{ marginRight: "16px" }} />
              )}
            </Row>
            <Row>
              <Auth />
              <Text
                title={item.authorizationType?.name ?? "-"}
                className={"marginLeft8"}
              />
            </Row>
            <Row>
              <Admin />
              <Text title={item.role.section.name} className={"marginLeft8"} />
            </Row>
          </Wrapper>
        ))}
      <MobilePagination data={data} />
    </div>
  );
};

export default MobileView;
