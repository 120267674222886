import { useReducer, useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";

import AdminForm from "./adminForm";
import { ACTIONS, adminReducer, initialState } from "./adminReducer";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  Picker,
  Text,
} from "../../shared/uiComponents";
import {
  AdminContainer,
  ButtonWrapper,
} from "../../components/StyledComponents";
import { blue100 } from "../../shared/Helpers/colors";

import { DispatchProperties, useSelector } from "../../redux/store";
import {
  getUser,
  getUserInsurances,
  updateUser,
} from "../../redux/State/userSlice";
import { assignRole } from "../../redux/State/identitySlice/userSlice";
import { getUserClients } from "../../redux/State/clientSlice/userClientSlice";
import { updateBillingCredentialed } from "../../redux/State/clientSlice/soapNoteSlice";

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();
const firstDayOfMonth = new Date(year, month, 1);

const UpdateAdmin = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { userId } = useParams();

  const loading = useSelector((state) => state.user.loading);
  const loadingRoles = useSelector((state) => state.role.loading);

  const saveDisabled = useSelector((state) => state.user.saveDisabled);
  const emailConfirmed = useSelector(
    (state) => state.identityUser.accountInfo.emailConfirmed
  );

  const [adminInfo, setAdminInfo] = useReducer(adminReducer, initialState);
  const [open, setOpen] = useState<boolean>(false);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);
  const [updatedInsuranceIds, setUpdatedInsuranceIds] = useState<Array<string>>(
    []
  );

  useEffect(() => {
    if (!userId) return;
    dispatch(getUser(userId))
      .then(unwrapResult)
      .then((response) => {
        const { role, authorizationType } = response;
        setAdminInfo({ type: ACTIONS.setState, payload: response });
        setAdminInfo({ type: ACTIONS.setRoleId, payload: role.id });
        setAdminInfo({
          type: ACTIONS.setAuthorizationType,
          payload: authorizationType?.id,
        });
      });
  }, [userId, dispatch]);

  useEffect(() => {
    if (!userId) return;
    dispatch(getUserInsurances(userId))
      .then(unwrapResult)
      .then((response) => {
        if (!response) return;
        const payload = response.map((x) => x.id);
        setAdminInfo({ type: ACTIONS.setInsurances, payload });
      });
  }, [userId, dispatch]);

  const updateAdminInfoHandler = () => {
    if (!userId) return;
    dispatch(
      assignRole({
        roleId: adminInfo.roleId,
        userId,
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(updateUser({ id: userId, data: adminInfo }))
          .then(unwrapResult)
          .then((response) => {
            const { addedInsurances, removedInsurances } = response;
            toast("Success");
            if (!addedInsurances.length && !removedInsurances.length) {
              navigate(-1);
            }
            setOpen(!!addedInsurances.length || !!removedInsurances.length);
            const added = addedInsurances.map((x) => x.id);
            const removed = removedInsurances.map((x) => x.id);
            setUpdatedInsuranceIds([...added, ...removed]);
          })
          .catch(errorNormalizer);
      })
      .finally(() => setOpen(false));
  };

  const accountStatusHandler = () => {
    setStatusOpen(true);
  };

  const accountStatusCloseHandler = () => {
    setStatusOpen(false);
  };

  const activationHandler = () => {
    if (!userId) return;
    const data = { ...adminInfo, isDeactivated: !adminInfo.isDeactivated };
    dispatch(updateUser({ id: userId, data }))
      .then(unwrapResult)
      .then(() => toast("Success"))
      .catch(errorNormalizer)
      .finally(() => {
        accountStatusCloseHandler();
        navigate(-1);
      });
  };

  return (
    <>
      <AdminForm adminInfo={adminInfo} setAdminInfo={setAdminInfo} />
      <AdminContainer>
        {emailConfirmed && (
          <>
            <CustomButton
              title={`${
                adminInfo.isDeactivated ? "Activate" : "Deactivate"
              } account`}
              onClick={accountStatusHandler}
              className={"marginBottom16"}
              secondaryButton={adminInfo.isDeactivated}
              loading={loading || loadingRoles}
            />
            <CustomAlert
              open={statusOpen}
              title={`Do you want to ${
                adminInfo.isDeactivated ? "Activate" : "Deactivate"
              }?`}
              onClose={accountStatusCloseHandler}
              Content={() => (
                <ButtonWrapper>
                  <CustomButton
                    title="Cancel"
                    secondaryButton
                    onClick={accountStatusCloseHandler}
                  />
                  <CustomButton title="Confirm" onClick={activationHandler} />
                </ButtonWrapper>
              )}
            />
          </>
        )}
        <CustomButton
          title={"Save"}
          onClick={updateAdminInfoHandler}
          disabled={saveDisabled}
          className="marginBottom16"
          loading={loading || loadingRoles}
        />
      </AdminContainer>
      <CustomAlert
        title="User information change"
        open={open}
        onClose={() => setOpen(false)}
        popupHeight="600px"
        Content={({ onClose }) => (
          <UpdateBillingDataContent
            updatedInsuranceIds={updatedInsuranceIds}
            onClose={onClose}
          />
        )}
      />
    </>
  );
};

const UpdateBillingDataContent = ({
  updatedInsuranceIds,
  onClose,
}: {
  updatedInsuranceIds: Array<string>;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [start, setStart] = useState<Dayjs>(dayjs(firstDayOfMonth));
  const [end, setEnd] = useState<Dayjs>(dayjs());

  const [clientIds, setClientIds] = useState<Array<string>>([]);

  const startDateHandler = (value: Dayjs | null) => {
    if (!value) return;
    setStart(value);
  };

  const endDateHandler = (value: Dayjs | null) => {
    if (!value) return;
    setEnd(value);
  };

  const updateSoapNotesHandler = () => {
    if (!userId) return;
    const startDate = dateNormalizer(start);
    const endDate = dateNormalizer(end);
    dispatch(
      updateBillingCredentialed({ userId, startDate, endDate, clientIds })
    )
      .then(unwrapResult)
      .then(() => toast("Success"))
      .catch(errorNormalizer)
      .finally(() => {
        navigate("/soap-notes");
        onClose();
      });
  };

  const updateClientIds = useCallback((id: string) => {
    setClientIds((prev) => {
      if (!prev.includes(id)) {
        return [...prev, id];
      }
      return prev.filter((x) => x !== id);
    });
  }, []);

  return (
    <div>
      <Text
        title={
          "Please make sure to set correct Supervising BCBA in all client profiles"
        }
        className="marginBottom16"
      />
      <Text
        title={
          "Please make sure billing information is correct in all client profiles"
        }
        className="marginBottom16"
      />
      <div
        style={{ display: "flex", gap: "16px", alignItems: "center" }}
        className="marginBottom16 marginTop16"
      >
        <Picker.CustomDate
          label="Start Date"
          onChange={startDateHandler}
          value={start}
        />
        <Picker.CustomDate
          label="End Date"
          onChange={endDateHandler}
          value={end}
        />
      </div>
      <ClientList
        insuranceIds={updatedInsuranceIds}
        setClientIds={updateClientIds}
        clientIds={clientIds}
      />
      <CustomButton
        title={"Update"}
        onClick={updateSoapNotesHandler}
        className="marginBottom16"
      />
    </div>
  );
};

const ClientList = ({
  insuranceIds,
  clientIds,
  setClientIds,
}: {
  insuranceIds: Array<string>;
  clientIds: Array<string>;
  setClientIds: (id: string) => void;
}) => {
  const { userId } = useParams();
  const dispatch = useDispatch<DispatchProperties>();

  const clients = useSelector((state) => state.userClient.userClients);
  const [clientsPageSize, setClientsPageSize] = useState<number>(8);

  useEffect(() => {
    const page = "1";
    if (!userId) return;
    dispatch(
      getUserClients({
        userId,
        page,
        pageSize: clientsPageSize.toString(),
        insuranceIds,
      })
    );
  }, [dispatch, userId, clientsPageSize, insuranceIds]);

  return (
    <>
      {!!clients.query?.length ? (
        <>
          <Text title={"Client name"} size="tinyBold" />
          {clients.query?.map((client, index) => (
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <CustomCheckBox
                item={{
                  id: client.id,
                  checked: clientIds.includes(client.id),
                }}
                onChange={() => setClientIds(client.id)}
              />
              <Link
                to={`/clients/client/${client.id}`}
                target="_blank"
                style={{
                  color: blue100,
                  lineHeight: "24px",
                }}
                key={index}
              >
                {`${index + 1}. ${client.fullName}`}
              </Link>
              {!!client.insurance ? (
                <Text title={`(${client.insurance.name})`} />
              ) : (
                "-"
              )}
            </div>
          ))}
          {clients.hasNextPage && (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Text
                title="Load More"
                size="smallBold"
                onClick={() => setClientsPageSize((prev) => prev + 8)}
              />
            </div>
          )}
        </>
      ) : (
        <Text
          title={"There is no client affected by insurance updates."}
          className="marginBottom16"
        />
      )}
    </>
  );
};

export default UpdateAdmin;
