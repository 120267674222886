import { FC } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";

import { Text, TableNoData, UserStatusBadge } from "../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../shared/uiComponents/Table/tableProperties";
import UserAction from "../../components/UserAction";

import { DispatchProperties } from "../../redux/store";
import { UserProperties } from "../../redux/API/userAPIProperties";
import { getUsers } from "../../redux/State/userSlice";

export interface AdminDataProperties extends TableDataProperties {
  query: Array<UserProperties> | null;
}

interface RowRendererProperties {
  data: UserProperties[];
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  { id: "1", name: "Email", orderBy: "email" },
  { id: "3", name: "Status", orderBy: "isDeactivated" },
  { id: "4", name: "Licensed or Limited", orderBy: "authorizationType" },
  {
    id: "5",
    name: "Role",
    titles: ["Admin", "BCBA", "BT", "All"], //All keyword is for reseting column
    titlesHeader: "Select Section:",
  },
  { id: "6", name: "Actions", width: "120px" },
];

export const initialUserTableState: UserProperties = {
  id: "",
  firstName: "",
  lastName: "",
  fullName: "",
  patronymic: "",
  numberOfClients: 0,
  phoneNumber: "",
  address: "",
  notes: "",
  email: "",
  numberOfNotes: 0,
  role: {
    id: "",
    name: "",
    section: {
      id: 0,
      name: "",
    },
  },
  createdBy: {
    id: "",
    fullName: "",
    role: "",
    authorizationType: {
      id: 0,
      name: "",
    },
  },
  authorizationType: {
    id: 0,
    name: "",
  },
  emailConfirmed: false,
  isDeactivated: false,
};

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const section = params.get("status");

    dispatch(
      getUsers({ page, pageSize, orderBy, direction, searchString, section })
    );
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Text title={row.fullName} size={"tiny"} />
          </TableCell>
          <TableCell>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text title={row.email} size={"tiny"} />
              {row.emailConfirmed && (
                <CheckOutlined style={{ marginRight: "16px" }} />
              )}
            </div>
          </TableCell>
          <TableCell>
            <UserStatusBadge active={!row.isDeactivated} />
          </TableCell>
          <TableCell>
            <Text
              title={!!row.authorizationType ? row.authorizationType.name : "-"}
              size={"tiny"}
            />
          </TableCell>
          <TableCell>
            <Text title={row.role.name} size={"tiny"} />
          </TableCell>
          <TableCell>
            <UserAction item={row} fetchUsers={fetchUsers} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
